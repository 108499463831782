@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

* { margin: 0; padding: 0; }
* {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

/*hide capture badge*/
.grecaptcha-badge { opacity:0;}

:root {
    --url-color: #ED1C24;
    --text-color: #3a3a3a;
    --bg-color-main: #F7F7F7;
    --bg-color-secondary: #E7F8FF;
    --bg-color-third: #E5E5E5;

    --logo-text-size: 70px;
    --heading-text-size: 40px;
    --subheading-text-size: 28px;
    --section-text-size: 20px;
    --subsection-text-size: 18px;
    --main-text-size: 15px;

    --main-padding: 30px;
    --half-padding: 15px;
}

body {
    background-color: var(--bg-color-main);
    font-family: 'Montserrat', SansSerif, serif;
    font-weight: 400;
    color: var(--text-color);
    line-height: 1.4;
}

h1 {
    font-weight: 700;
    font-size: var(--heading-text-size);
}

h2 {
    font-weight: 700;
    font-size: var(--subheading-text-size);
}

h3 {
    font-weight: 700;
    font-size: var(--section-text-size);
}

h4 {
    font-weight: 700;
    font-size: var(--main-text-size);
}


p {
    font-size: var(--main-text-size);
}

a {
    color: var(--url-color);
    text-decoration: none;
}

div, p {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.separator {
    height: 1px;
    margin: 0;
    padding: 0;
    border-bottom: #cccccc 1px solid;
}

.displayMobile {
    display: none;
}

.displayWeb {
    display: block;
}

.shadow {
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

.border {
    border: #cccccc 1px solid;
}

/*
/ MAIN CONTAINER
 */

.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100vh;
}

.separator {
    height: 10px;
}

.text-overflow-hidden {
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
}



/*
/ Controls
 */

.controlsHeaderContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.cardsContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
}

.cardsContainer-box {
    width: 100%;
    overflow-x: scroll;
}

.whiteBox {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 6px;
}

.card-image-container {
    width: 100%;
    height: 80px;
}

.card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.card-activity-container {
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    display: flex;
    margin-bottom: 20px;
}

/*
/* POPUPS
 */

.popup {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.7);
}
.popup_inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #000;
    outline: none;
    line-height: 1.2;
}

.popup_inner_close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.popup_inner_right {
    position: absolute;
    right: 20px;
    top: 50vh;
}

.popup_inner_left {
    position: absolute;
    left: 20px;
    top: 50vh;
}

/*
/ Buttons
 */

button {
    font-size: var(--main-text-size);
    cursor: pointer;
    outline: none;
}

.button {
    padding: 15px 25px;
    background-color: var(--url-color);
    border-width: 0;
    font-size: var(--main-text-size);
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
}

.button-round {
    width: 44px;
    height: 44px;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: unset;
}

.button-clean {
    padding: 15px 25px;
    border-radius: 25px;
    border: var(--url-color) 1px solid;
    background-color: #ffffff;
    color: var(--url-color);
}

.button-clean-small {
    padding: 8px 15px;
    border-radius: 25px;
    border: var(--url-color) 1px solid;
    background-color: #ffffff;
    color: var(--url-color);
}

.button-clean-small:disabled {
    opacity: .3;
}

.button-small {
    background-color: var(--url-color);
    border-width: 0;
    font-size: var(--main-text-size);
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    padding: 8px 12px;
    font-weight: normal;
}

.button-link {
    padding: 0;
    background-color: unset;
    border: unset;
    color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
}

.button-link-small {
    padding: 0;
    background-color: unset;
    border: unset;
    color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
    font-size: 14px;
}

.button-link-regular {
    background-color: unset;
    padding: 5px 10px;
    border-radius: 25px;
    border: transparent 1px solid;
    color: var(--url-color);
    text-underline-color: var(--url-color);
    font-weight: normal;
    text-decoration: underline;
}

.button-link-selected {
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 25px;
    border: transparent 1px solid;
    color: var(--url-color);
    text-decoration: none;
    font-weight: normal;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

input, select, textarea {
    min-width: 150px;
    max-width: 400px;
    width: 100%;
    height: 34px;
    padding: 5px 25px 5px 15px;
    font-size: var(--main-text-size);
    outline: none;
    border-radius: 10px;
    border: #FFFFFF 1px solid;
    background-color: #ffffff;
    color: var(--text-color);
    -ms-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

select {
    opacity: .8;
}

input::placeholder, textarea::placeholder {
    font-size: 12px;
    color: #cccccc;
}

select {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-size: .65em;
    background-position: calc(100% - 1em) center;
    background-color: #ffffff;

}

textarea {
    resize: none;
    height: 200px;
    max-width: 100%;
}

textarea::placeholder {
    color: #a0a0a0;
    font-family: "Montserrat", SansSerif, serif;
}


.yellow {
   background-color: #FFEDAC;
}

.blue {
    background-color: #039BE5;
    color: #FFFFFF;
}

.green {
    background-color: #E9FFAC;
}

.gray {
    background-color: #f0f0f0;
}

.white {
    background-color: #ffffff;
}

.slot {
    border-radius: 8px;
    padding: 4px 8px;
    width: fit-content;
    cursor: pointer;
    margin-left: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.disabled {
    cursor: default;
}

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

.patientContainer {
    max-height: 300px;
    overflow-y: scroll;
    padding: var(--half-padding);
}

.paddingHalf {
    padding: var(--half-padding);
}

/*
/ Other
 */

.alert-color {
    color: #ff0000;
}

.passive-color {
    color: #aaaaaa;
}

/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:980px) {

    .flex2col {
        display: block;
    }

    .separation-section {
        display: block;
    }

    .left {
        width: 100%;
        margin-bottom: 20px;
    }

    .right {
        width: 100%
    }

    .header-img-container {
        display: none;
    }
}

@media (min-width: 600px) and (max-width:1090px) {
}

@media (min-width: 450px) and (max-width:600px) {
    :root {
        --heading-text-size: 30px;
        --section-text-size: 18px;
        --subheading-text-size: 20px;
        --main-padding: 20px;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --logo-text-size: 46px;
        --heading-text-size: 26px;
        --section-text-size: 16px;
        --subheading-text-size: 15px;
        --main-text-size: 14px;
        --main-padding: 20px;
        --half-padding: 10px;
    }

    .activity {
        width: 100%;
        margin-right: 15px;
        margin-bottom: 20px;
    }

    .displayMobile {
        display: block;
    }

    .displayWeb {
        display: none;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 40px;
        --heading-text-size: 24px;
        --subheading-text-size: 15px;
        --main-text-size: 13px;
        --main-padding: 15px;
        --half-padding: 8px;
    }

}
